import React from 'react';
import {Button, Label} from "@blueprintjs/core";
import Redirect from "react-router-dom/es/Redirect";

class OrganizationInput extends React.Component {

  constructor(props){
    super(props);
    this.state={}
  }

  componentWillMount() {
    const organizationPublicId = localStorage.getItem("goodbricks-organization-public-id")
    if(organizationPublicId){
      this.setState({redirectToOrganizationTerminal: true});
      this.setState({organizationPublicId: organizationPublicId})
    }
  }

  handleInputChange = (event) => {
    this.setState({organizationPublicId: event.target.value});
  }

  handleOrganizationSubmit = () => {
    window.localStorage.setItem("goodbricks-organization-public-id", this.state.organizationPublicId);
    this.setState({redirectToOrganizationTerminal: true});
  }

  render() {
    if (this.state.redirectToOrganizationTerminal === true) {
      return <Redirect to={"/"+this.state.organizationPublicId} />
    }
    return (
        <div className="row u-full-width">
          <div id="left-content" className="section-flex twelve columns py-2 px-4 bg-lighter">
            <div className="row py-1">
              <div className="twelve columns">
                <Label>
                  Organization Id
                  <input className='bp3-large bp3-input' type='text' placeholder="" onChange={this.handleInputChange}/>
                </Label>
              </div>
            </div>
            <div className="row py-1">
              <div className="twelve columns">
                <Button intent="success" large onClick={this.handleOrganizationSubmit}> Start Terminal</Button>
              </div>
            </div>
          </div>
        </div>
    );
  }
};

export default OrganizationInput;
